import GithubIcon from "../resources/github.png";
import LinkedinIcon from "../resources/linkedin.png";
import MailIcon from "../resources/mail.png";

import SocialIcon from "./SocialMediaIcon";

function Hero() {
  return (
    <>
      <div className="mb-10 text-center ">
        <h2 className="pb-3 text-5xl font-bold text-transparent bg-clip-text bg-gradient-to-tl from-blue-600 to-green-300">
          Haider Jirrie
        </h2>
        <h3 className="pb-3 text-2xl text-bold">
          Student ICT Software Engineering
        </h3>
        <p className="max-w-lg mx-auto font-medium leading-8 text-md text-slate-950">
          Welcome! As an HBO-ICT student at The Hague University of Applied
          Sciences, I am currently looking for a third-year internship at a
          company where I can take my first steps as a junior Software Engineer.
          Below you will find my CV, and you can also contact me via LinkedIn or
          email.
        </p>
        {/* <a href="https://haiderjirrie.com/CV Haider Jirrie.pdf" download> */}
          {/* <button className="px-4 mt-4 text-lg font-bold text-white transition duration-300 ease-in-out rounded-md cursor-pointer  bg-gradient-to-tr from-sky-500 to-yellow-300 hover:scale-125">
            Download CV
          </button> */}
        {/* </a> */}
      </div>
      <div className="flex justify-center max-w-xs mx-auto mb-24 gap-14">
        <SocialIcon
          name="LinkedIn"
          image={LinkedinIcon}
          link="https://www.linkedin.com/in/haiderjirrie/"
        />
        <SocialIcon
          name="GitHub"
          image={GithubIcon}
          link="https://github.com/HaiderJirrie"
        />
        <SocialIcon
          name="E-mail"
          image={MailIcon}
          link="mailto: haiderjirrie@gmail.com"
        />
      </div>
    </>
  );
}

export default Hero;
