import HHS from "../resources/hhs.jpg";
import AdHoc from "../resources/AdHoc.png";

import ExperienceCard from "./ExperienceCard";

function Experience() {
  return (
    <div className="justify-center mx-auto mb-4 xl:flex gap-14">
      <div className="pt:-0 md:pt-10">
        <h3 className="pb-0 text-3xl font-bold text-center md:pb-5">
          Education
        </h3>
        <ExperienceCard
          timespan="September 2021 - June 2025"
          name="The Hague University of Applied Sciences"
          gradientFrom="#65a30d"
          gradientTo="#84cc16"
          image={HHS}
          location="Den Haag"
          title="HBO-ICT, Software Engineering"
          text="At the moment, I am a second-year HBO-ICT student and I have obtained my propaedeutic diploma. During my first year, I chose the Software Engineering specialization. I consider myself a highly ambitious student with a great passion for the field."
        />
      </div>

      <div className="pt-0 md:pt-10">
        <h3 className="pb-0 text-3xl font-bold text-center md:pb-5">
          Work experience
        </h3>
        <ExperienceCard
        timespan="May 2023"
        name="Adhoc IT Services"
        gradientFrom="#0284c7"
        gradientTo="#0ea5e9"
        image={AdHoc}
        title="Developed a webapp"
        text={ <p>I had the opportunity to create a webapplication for a small IT businnes, <a className="font-bold underline " href="https://www.adhocit.nl/" target="_blank" rel="noreferrer">Ad Hoc</a>. As for the frontend I used ReactJS and Tailwind, I also intergrated my own webAPI which allows vistors to send emails to the company through forms on the website. I used .NET for the backend.</p>}/>
      </div>
    </div>
  );
}

export default Experience;
